<template lang="pug">
.main()
    .move-light.background
        .container-xl(style="max-width: 1400px")
            .row.no-gutters
                .col-12
                    .main-view-web
                        .row
                            .col-12.col-md-8
                                
                                h1(style="max-width: 500px; font-size: 2em").mb-5 The #1 Solution for Startup Scouting and Innovation in the <span class="hcolor">Health Industry</span>
                                p(style="max-width: 500px; font-size: 1em")     The global startup ecosystem in the health industry is only one click away! Our comprehensive database provides insights into over 170,000 companies in health and daily curated news. 
                            .col-12.col-md-4
                                b-card()
                                    h2 Request a free demo
                                    b-form(@submit.stop.prevent="send(form)")
                                        div(v-if="!result")
                                            b-form-group(label="Name")
                                                b-input(type="text", v-model="form.name", required)
                                            b-form-group(label="Email")
                                                b-input(type="email", v-model="form.email", required)
                                            b-check(required, name="ps1" id="ps1", v-model="form.accept")
                                                label(for="ps1").small(style="text-transform: none; font-weight: normal; margin-top: 2px") I accept the <a href="/web/imprint" target="_blank" style="text-decoration:underline; text-transform: none; color: black">data privacy statement</a>
                                                
                                        
                                        div(v-if="result")
                                            .text-muted Thanks. Click below to choose a demo slot. We'll get in touch with you.
                                            b-button(variant="primary",  :href="'https://calendly.com/sebastian-voigt/hy-esm-45-mins?email=' + form.email + '&name=' + form.name + '&a1=health'" target="_blank").mt-3.form-control Book demo slot
                                        div(v-if="!result")
                                            b-button(type="submit", variant="primary").mt-3.form-control Request Demo


    .container
        .row.no-gutters
            .col-12
                .main-view(style="max-width: 1440px;").m-auto
                    .row
                        .col-12.col-md-4.mt-4
                            .text-center
                                img(src="/streamline-icon-search@140x140.png").adicon
                                h2.text-center Scouting in health
                                p Quickly find the most relevant startups, vendors and innovatorsbased on our <u>mediacal taxonomy</u> and <u>pre-defined health topics</u>. 

                        .col-12.col-md-4.mt-4
                            .text-center
                                
                                img(src="/streamline-icon-pie-line-graph-desktop@140x140 (2).png").adicon
                                h2 Dealflow
                                p Manage investment and partnership opportunities collaboratively, and get suggestions to investigate new companies.
                        
                        .col-12.col-md-4.mt-4
                            .text-center
                                img(src="/streamline-icon-business-deal-negotiate@140x140.png").adicon
                                h2.text-center Relationship Management
                                p Fill your corporate ecosystem with relevant contacts, track its development and systematically fill white spots.
                        
                        .col-12.col-md-4.mt-4
                            .text-center
                                img(src="/analytics.png").adicon
                                
                                h2.text-center Topic Analytics
                                p Identify early signals for emerging markets with our topic analytics function, combining VC flow, founding activities and other growth metrics.

                        .col-12.col-md-4.mt-4
                            .text-center
                                img(src="/streamline-icon-browser-page-hierarchy@140x140.png").adicon
                                
                                h2.text-center Ecosystem News
                                p Find the latest and most relevant news from your ecosystem and easily share them with the right people.

                        .col-12.col-md-4.mt-4
                            .text-center
                                i.fas.fa-certificate.adicon.text-success
                                
                                h2.text-center FDA and ISO Certifcations
                                p Scout and rank startups by found Cerfitications, like FDA, ISO, CE Marks, Medical Device Classes etc.

                        
                            

    .move.background
        .container-xl(style="max-width: 1440px;")
            .row.no-gutters
                .col-12
                    .main-view-web(style="padding: 60px 30px")

                        h2 We've got you covered.

                        p.text-white Rich pre-setup taxonomy and categories like <span class="hcolor">clinical diagnoses</span> and <span class="hcolor">health innovation topics</span>:
                        h6(style="max-width: 880px")
                            b-badge(variant="secondary").mr-1 Alzheimer
                            b-badge(variant="secondary").mr-1 Cancer & Oncology
                            b-badge(variant="secondary").mr-1 Cardiovascular
                            b-badge(variant="secondary").mr-1 Cosmetics & Aesthetics
                            b-badge(variant="secondary").mr-1 Dental
                            b-badge(variant="secondary").mr-1 Diabetes
                            b-badge(variant="secondary").mr-1 Epilepsy
                            b-badge(variant="secondary").mr-1 Female Health
                            b-badge(variant="secondary").mr-1 Fertility
                            b-badge(variant="secondary").mr-1 Gastrointestinal Tract
                            b-badge(variant="secondary").mr-1 Genomics
                            b-badge(variant="secondary").mr-1 Gynecology
                            b-badge(variant="secondary").mr-1 Immunology
                            b-badge(variant="secondary").mr-1 Infectious Disease
                            b-badge(variant="secondary").mr-1 Mental Health
                            b-badge(variant="secondary").mr-1 Obesity
                            b-badge(variant="secondary").mr-1 Paediatrics
                            b-badge(variant="secondary").mr-1 Pulmonology
                            b-badge(variant="secondary").mr-1 Rheumatology
                            b-badge(variant="secondary").mr-1 Urology
                            b-badge(variant="secondary").mr-1 Veterinary

                            .mt-4
                            
                            b-badge(variant="secondary").mr-1 Alternative Medicine
                            b-badge(variant="secondary").mr-1 Biopharma
                            b-badge(variant="secondary").mr-1 Cannabis
                            b-badge(variant="secondary").mr-1 Childcare
                            b-badge(variant="secondary").mr-1 Clinical Trials
                            b-badge(variant="secondary").mr-1 Diagnostics
                            b-badge(variant="secondary").mr-1 Disease Management & Therapeutics
                            b-badge(variant="secondary").mr-1 Drug Discovery
                            b-badge(variant="secondary").mr-1 Elder Care
                            b-badge(variant="secondary").mr-1 Emergency Medicine
                            b-badge(variant="secondary").mr-1 Employee Benefits
                            b-badge(variant="secondary").mr-1 First Aid
                            b-badge(variant="secondary").mr-1 Fitness & Wellness
                            b-badge(variant="secondary").mr-1 Genetics
                            b-badge(variant="secondary").mr-1 Health Insurance
                            b-badge(variant="secondary").mr-1 Health Marketing
                            b-badge(variant="secondary").mr-1 Healthcare
                            b-badge(variant="secondary").mr-1 Home Healthcare
                            b-badge(variant="secondary").mr-1 Hospital
                            b-badge(variant="secondary").mr-1 Medical
                            b-badge(variant="secondary").mr-1 Medical Equipment
                            b-badge(variant="secondary").mr-1 Nursing & Residential Care
                            b-badge(variant="secondary").mr-1 Nutraceutical
                            b-badge(variant="secondary").mr-1 Nutrition & Dietary Supplements
                            b-badge(variant="secondary").mr-1 Outpatient Care
                            b-badge(variant="secondary").mr-1 Personal Health
                            b-badge(variant="secondary").mr-1 Pharmaceutical
                            b-badge(variant="secondary").mr-1 Predictive Analytics & Risk Scoring
                            b-badge(variant="secondary").mr-1 Protheses & Implants
                            b-badge(variant="secondary").mr-1 Real-world Evidence
                            b-badge(variant="secondary").mr-1 Rehabilitation
                            b-badge(variant="secondary").mr-1 Remote Monitoring
                            b-badge(variant="secondary").mr-1 Screening & Diagnostics
                            b-badge(variant="secondary").mr-1 Telehealth
                            b-badge(variant="secondary").mr-1 Virtual Care Delivery
                            b-badge(variant="secondary").mr-1 mHealth






    


    .container-xl
        .row.no-gutters
            .col-12
                .main-view
                
                    .row
                            .col-1.d-none.d-md-block
                            .col-12.col-md-6
                                .float-right
                                    div(style="background-color: white; max-width: 800px").p-1.shadow
                                        img(src="/scouthealth.png").img-fluid
                            
                            .col-12.col-md-4
                                label Scout your ecosystem
                                h2 Search for startups, vendors and innovators in health.
                                p Find companies based on an expert health taxonomy.
                            .col-1.d-none.d-md-block


    .container-xl
        .row.no-gutters
            .col-12
                .main-view
                
                    .row
                            .col-2.d-none.d-md-block
                            .col-12.col-md-4
                                .float-right
                                    div(style="background-color: white; max-width: 600px").p-1.shadow
                                        img(src="/EcosystemSimilarity.gif").img-fluid
                            
                            .col-12.col-md-4
                                label Grow your ecosystem
                                h2 AI-driven scouting technology
                                p Find more companies with our semantic search technology and automatic recommendations.
                            .col-2.d-none.d-md-block
                            
    
    .container-xl
        .row.no-gutters
            .col-12
                .main-view
                
                    .row
                            .col-2.d-none.d-md-block
                            .col-12.col-md-4
                                .float-right
                                    div(style="background-color: white; max-width: 600px").p-1.shadow
                                        img(src="/KanbanMove1.gif").img-fluid
                               
                            .col-12.col-md-4
                                label Projects and processes
                                h2 Kanban boards and project management
                                p Use account assignments, deal flow funnels and the project-level permission model to manage different projects and work streams.
                            .col-2.d-none.d-md-block
    .container-xl
        .row.no-gutters
            .col-12
                .main-view
                
                    .row
                            .col-2.d-none.d-md-block
                            .col-12.col-md-4
                                .float-right
                                    div(style="background-color: white; max-width: 600px").p-1.shadow
                                        img(src="/plugin.gif").img-fluid
                            
                            .col-12.col-md-4
                                label Team work
                                h2 Integration into your daily workflow
                                p The Ecosystem Manager’s browser extension allows you to access your entire ecosystem information anytime in your existing workflow.
                            .col-2.d-none.d-md-block


    .container
        .row.no-gutters
            .col-12
                .main-view-web(style="max-width: 1440px;").m-auto
                    .row
                        .col-12.col-md-4
                            .text-center.mt-4
                                img(src="/streamline-icon-database-check@140x140.png").adicon
                                h2.text-center Secure server & backups
                            

                        .col-12.col-md-4
                            .text-center.mt-4
                                img(src="/streamline-icon-server-lock@140x140.png").adicon
                                h2.text-center Hosting in Germany

                            
                        .col-12.col-md-4
                            .text-center.mt-4
                                img(src="/streamline-icon-shield-check@140x140.png").adicon
                                h2.text-center GDPR compliant
    Footer()
            
</template>

<script>

  
import Vue from 'vue'
import User from '@/components/User'
import Footer from '@/views/website/Footer'
import Config from '@/config'

export default {
  name: 'Index',
  props: {
    title: String,
    
  },


  data: function () {
      return {
          selectAll: false,
          form: {name: "", organization: "health edition", tel: "", email: ""},
          result: null
      }
  },

  mounted: function() {
     
  },

  methods: {
      send(form) {
          Vue.http.post(Config.config.base_host+'/demo_request', form).then(res => {
            this.result = res;
            // this.form = {name: "", organization: "", tel: "", email: ""};
          });
      }
      
  },
  computed: {
    
  },
  components: {
      User,
      Footer
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    body {
        background-color: #fff;
    }
</style>
<style scoped lang="scss">
    @import '@/_vars.scss';

    .btn-outline-secondary {
        color: $light;
        border-color: $light;
    }
    h1 {
        font-family: "IBM Plex Serif" !important;
        font-weight: 800;
        font-size: 2em;   
    }
    h2 {
        font-family: "IBM Plex Serif" !important;
        font-weight: 800;
        font-size: 1.5em;   
    }


    .icon {
        max-width: 25px;
    }

    .footer {
      
        background-color: $primary;
        color: $light !important;
        a {
            color: $light;
        }
        label {
            opacity: 0.6;
        }
        h1 {
            font-family: "IBM Plex Serif" !important;
            font-weight: 800;
            color: $light !important;
        }
    }
    .move {
        position: relative;
        color: $secondary;
        h2 {
            color: $light;
        }
        background-image: linear-gradient(53deg, #003C50 0%, #003C50 64%, #000000 100%);
        overflow: hidden;
    }

    .move:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
        opacity: 0.1;
        background-image: url('/hy_pattern_weiß_rgb.png');
        //background-repeat: no-repeat;
        background-position: 10% 0;
        -ms-background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;
    }

    .move-light {
        color: $light;
        h1 {color: $light;}
        background-image: linear-gradient(53deg, #003C50 25%, #003C50 75%, #003C50 100%);
        overflow: hidden;

        @media (max-width: 800px) {
            background-image: linear-gradient(53deg, #003C50 25%, #003C50 75%, #003C50 100%);
            h1 {color: $light;}
        }

    }

  

    .move-light:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: -100;
        opacity: 0.1;
        background-image: url('/hy_pattern_weiß_rgb.png');
        //background-repeat: no-repeat;
        background-position: 10% 0;
        -ms-background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;
    }


    .main-view {
        padding: 60px;
    }
    .main-view-web {
        padding: 30px;
        padding-top: 60px;
        padding-bottom: 60px;
 
        
    }

    .adicon {
        
        padding: $pad;
        height: 100px;
        font-size: 4em;
        
    }

    .red {
        color: #ff595e;
    }

    .lblue {
        color: #d2dee8;
    }

    .azure {
        color: #25b3d2
    }

    .yellow {
        color: #f7d864;
    
    }

    .purple {
        color: #6e6eb3;
    }

    .green {
        color: #47be98;
    }

      .hcolor {
        color: #03FF00;
    }

</style>
