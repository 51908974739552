<template lang="pug">

    .main.mt-5
        .container-xl
            .row.no-gutters
                .col-12
                  .main-view    
                    
                   
                    .mt-3.mb-3 <a href="/web/index">Home</a>

                    div(v-if="$route.query.expired").alert.alert-danger Your demo has expired, please contact sales.

                    h2 Contact Sales
                    h5 Dr. Sebastian Voigt
                    <a href="tel:+49 151 4404 7650">+49 151 4404 7650</a><br/>
                    <a href="mailto:sales@ecosystem-manager.co">contact@ecosystem-manager.co</a>

                    h2.mt-5 Contact Support
                    <a href="mailto:contact@ecosystem-manager.co">contact@ecosystem-manager.co</a>
                
                    
                    h2.mt-5 Address
                    p hy Ecosystem Manager is a product of Axel Springer hy Technologies GmbH, a full subsidiary company of Axel Springer hy GmbH
                    
                    | Axel Springer hy Technologies GmbH<br/>
                    | Axel-Springer-Straße 65<br/>
                    | 10888 Berlin<br/>
        
        .row.mt-5 &nbsp;
        .row.mt-5 &nbsp;
        .row.mt-5 &nbsp;
        
        Footer().mt-5
               
</template>   

<script>


import Footer from '@/views/website/Footer'

export default {
  name: 'ESMWeb',


  watch: {
    
  },
  data () {
    return {
        demo_data: {name: "", organization: "", tel: "", email: ""},
        activeShow: 1,
        result: null,
        on_field: null,
        stages: [],
        blocks: [
           
        ],
    
    }
  },

  mounted() {
      
     

  },
  
  computed: {

  
  },
  methods: {

      

  },
  components: {
    Footer
  }
}
</script>

<style lang="scss">


</style>

<style scoped lang="scss">
  h1 {
        font-family: "IBM Plex Serif" !important;
        font-weight: 800;
        font-size: 2em;   
    }
    h2 {
        font-family: "IBM Plex Serif" !important;
        font-weight: 800;
        font-size: 1.5em;   
    }

</style>