<template lang="pug">
.main
    .footer
        .container-xl(style="max-width: 1400px")
            .row.no-gutters
                .col-12
                    .main-view-web()
                        .row

                            .col-12.col-md-4
                                   
                                    label Menu
                                    router-link(to="/web/index").d-block.mt-1 Home
                                    router-link(to="/web/contact").d-block.mt-1 Contact
                                    router-link(to="/web/contact").d-block.mt-1 Support
                                    
                                    router-link(to="/web/imprint").d-block.mt-1 Imprint
                                    router-link(to="/web/imprint").d-block.mt-1 Privacy
                                
                                    img(src="/hy_t_w.svg", style="height: 90px; margin-left: -14px").mt-5
                            .col-12.col-md-2
                                //- label Ecosystem Manager for
                                
                               
                                //- .text-muted.d-block.mt-1 Innovation units
                                //- .text-muted.d-block.mt-1 Procurement Manager
                                //- .text-muted.d-block.mt-1 Knowledge Manager
                                //- .text-muted.d-block.mt-1 Data Scientists
                                //- .text-muted.d-block.mt-1 Strategy Consultancies
                                //- .text-muted.d-block.mt-1 VCs / CVCs
                                //- .text-muted.d-block.mt-1 CEOs
                                //- .text-muted.d-block.mt-1 Innovations Units
                                
                                //- .mt-4
                                //-     a(href="mailto:contact@ecosystem-manager.co") Contact us


                            //.col-12.col-md-6
                                label Request a free demo
                                b-card()
                                    h2 Request a free demo
                         
                                    b-form(@submit.stop.prevent="send(form)")
                                        b-form-group(label="Name")
                                            b-input(type="text", v-model="form.name", required)
                                        b-form-group(label="Email")
                                            b-input(type="email", v-model="form.email", required)
                                        b-input-group()
                                                               
                                            b-check(required, name="ps1" id="ps11", v-model="form.accept")
                                            label(for="ps11").small(style="text-transform: none; font-weight: normal; margin-top: 2px") I accept the <a href="/web/imprint" target="_blank" style="text-decoration:underline; text-transform: none; color: black">data privacy statement</a>
                                                    
                                        div(v-if="result")
                                            .text-muted Thanks. Click below to choose a demo slot. We'll get in touch with you.
                                            b-button(variant="primary", href="https://calendly.com/sebastian-voigt/hy-esm-45-mins" target="_blank").mt-3.form-control Book demo slot
                                        div(v-if="!result")
                                            b-button(type="submit", variant="primary").mt-3.form-control Request Demo
            
</template>

<script>


import Vue from 'vue'
import User from '@/components/User'
import Config from '@/config'

export default {
  name: 'Footer',
  props: {
    title: String,
    
  },

  data: function () {
      return {
          selectAll: false,
          form: {name: "", organization: "", tel: "", email: ""},
          result: null
      }
  },

  mounted: function() {
     
  },

  methods: {
      send(form) {
          Vue.http.post(Config.config.base_host+'/demo_request', form).then(res => {
            this.result = res;
            this.form = {name: "", organization: "", tel: "", email: ""};
          });
      }
      
  },
  computed: {
    
  },
  components: {
      User
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
    body {
        background-color: #fff;
    }
</style>
<style scoped lang="scss">
    @import '@/_vars.scss';
    h1 {
        font-family: "IBM Plex Serif" !important;
        font-weight: 800;
        font-size: 2em;   
    }
    h2 {
        font-family: "IBM Plex Serif" !important;
        font-weight: 800;
        font-size: 1.5em;   
    }


    .icon {
        max-width: 25px;
    }

    .footer {
      
        background-color: $primary;
        color: $light !important;
        a {
            color: $light;
        }
        label {
            opacity: 0.6;
        }
        h1 {
            font-family: "IBM Plex Serif" !important;
            font-weight: 800;
            color: $light !important;
        }
    }
    .move {
        position: relative;
        
        background-image: linear-gradient(53deg, #003C50 0%, #003C50 64%, #000000 100%);
        overflow: hidden;
    }

    .move:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: 1;
        opacity: 0.1;
        background-image: url('/hy_pattern_weiß_rgb.png');
        //background-repeat: no-repeat;
        background-position: 10% 0;
        -ms-background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;
    }

     .move-light {
        color: $light;
        position: relative;
        z-index: -120;
        background-image: linear-gradient(53deg, rgb(255, 255, 255) 25%, #003C50 75%, #003C50 100%);
        overflow: hidden;
    }

    .move-light:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 50%;
        top: 0;
        width: 50%;
        height: 100%;
        z-index: -100;
        opacity: 0.1;
        background-image: url('/hy_pattern_weiß_rgb.png');
        //background-repeat: no-repeat;
        background-position: 10% 0;
        -ms-background-size: cover;
        -o-background-size: cover;
        -moz-background-size: cover;
        -webkit-background-size: cover;
        background-size: cover;
    }



    .main-view-web {
        padding-top: 60px;
        padding-bottom: 60px;
    }

    .adicon {
        
        padding: $pad;
        height: 100px;
        
    }

    .red {
        color: #ff595e;
    }

    .lblue {
        color: #d2dee8;
    }

    .azure {
        color: #25b3d2
    }

    .yellow {
        color: #f7d864;
    
    }

    .purple {
        color: #6e6eb3;
    }

    .green {
        color: #47be98;
    }

</style>
