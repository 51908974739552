<template lang="pug">
.main()
    .sticky-top()
      
        .submenu
            .container-xl(style="max-width: 1400px")

                .row.no-gutters.submenu-inner()
                    .col-3
                        .headline-submenu 
                            a(:href="'/web/'+edition")
                                img(src="/hy_esm_w_align.svg").mr-1.logo 
                                .edition {{editionLabel}}
                          
                    .col-9
                        .float-right
                            b-navbar(toggleable="lg").header
                                b-navbar-toggle(target="nav-collapse")
                                b-collapse(id="nav-collapse" is-nav).ml-auto
                                    b-navbar-nav
                                        b-nav-item(:href="'/web/'+edition").mr-3 Home
                                        //.d-inline-block( @mouseover="$refs['dd1'].show()", @mouseleave="$refs['dd1'].hide()").mr-3
                                            b-nav-item-dropdown(ref="dd1" text="Solution")
                                                b-dropdown-item(href="/web/corporates") For Corporates
                                                b-dropdown-item(href="/web/consultants") For Consultants
                                                b-dropdown-item(href="/web/investors") For Investors 
                                        b-nav-item(href="https://ecosystem-manager.us10.list-manage.com/subscribe?u=207ff523e52dd00038e89ff23&id=6713f99fe3", target="_blank").mr-1  Newsletter
                                        
                                        b-nav-item(href="https://www.ecosystem-manager.co/users/login").mr-3 Login

                                        b-nav-item(:href="'/web/'+edition")
                                            .float-right
                                                b-button(size="sm", variant="outline-secondary") Request Demo

                            
                .row.no-gutters.submenu-inner
                
                    //.col-md-12    
                        .menu                
                            router-link(to="browse").mr-5 News 
                            //router-link(to="curator").mr-5 Curator 
              
    
    router-view(name="main")
            
</template>


<script>
export default {
  name: 'SubmenuWebsite',
    data() {
        return {
            editionLabel: "",
            edition: "index",
        }
    },
  props: {
   
  },
  methods: {
   
  },
  computed: {
   
  },
    mounted() {
        if(this.$route.path.indexOf('health') > -1) {
            this.edition = "health";
            this.editionLabel = "health";
        }
        if(this.$route.path.indexOf('energy') > -1) {
            this.edition = "energy";
            this.editionLabel = "energy";
        }
    },
}
</script>

<style>
    body {
        background-color: #fff;
    }

    .navbar-nav {
   
        color: #fff !important;
     
    }


</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

    @import '@/_vars.scss';

         .navbar-nav {
   
        color: #fff !important;
     
    }

    .edition {
        font-size: 0.875rem;
        font-weight: bold;
        position: absolute;
        top: 18px;
        left: 100px;
        color: #03FF00;
    }

    .hcolor {
        color: #03FF00;
    }



    * {
        font-family: "IBM Plex Sans" !important;
    }
  
    .btn-outline-secondary {
        color: $light;
        border-color: $light;
    }

    .head, h1 {
        font-family: "IBM Plex Serif" !important;
        font-weight: 800;
        font-size: 1em;
        color: $light;
    }

    .main-view {
        //background-color: $light;
        font-family: "IBM Plex Sans" !important;
    }

     .navbar-light .navbar-nav .nav-link, a.navbar-light a.navbar-nav a.nav-link a.nav-link.dropdown-toggle {

            color: $light !important;

            span {
                color: $light !important;
            }
        }
    
    .submenu { 
        background-color: $primary;
        color: $light;
        border: 0;
        a, .light {
            color: $light;
            display: inline-block;
        }
        .headline-submenu {
            color: $light;
        }
        
       
    
    }

  .logo {
      
      height: 40px;
      
      &:hover {
        opacity: 1;
      }
    }
</style>
